import React, { useEffect, useState } from "react";
import { useAuth } from "../ContextAndHooks/AuthContext";
import "./Depositeaccount.css";
import useSWR from "swr";
import { RiBankCard2Line, RiBankLine, RiBarcodeLine, RiMessage2Line, RiQrCodeLine, RiUser3Line } from "react-icons/ri";
import { fetchData, postData } from "../api/ClientFunction";
import { useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { CiLock } from "react-icons/ci";
import { MdOutlineBadge } from "react-icons/md";
import { CiMobile3 } from "react-icons/ci";
import { PiMoneyFill } from "react-icons/pi";



const WithdrawalAccount = () => {
  

 
  const { user, bank } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: user?.name || user?.name_user || "",
      email: user?.email || "",
      phone: user?.phone || "",
      amount: "",
      txn_id: "",
      screenshot: null,
    },
  });
  const [money, setMoney] = useState();
  const [phone, setPhone] = useState();
  console.log(phone)
  
  useEffect(() => {
    if (user && user.money && user.phone) {
      setMoney(user.money);
      setPhone(user.phone);
    }
  }, [user]);

  const onSubmit = async (data) => {
    // Trim all data values
    Object.keys(data).forEach((key) => {
      if (typeof data[key] === 'string') {
        data[key] = data[key].trim();
      }
    });
  
    if (!user?.phone) {
      return toast.error("Can't withdraw! Please login again.");
    }
  
    if (data.phone !== user.phone) {
      return toast.error("Phone Number must be your registered phone number.");
    }
  
    if (data.withdrawamount < 100) {
      return toast.error("Minimum withdraw amount is 100.");
    }
  
    const formData = new FormData();
    formData.append("phone", data.phone);
    formData.append("withdrawamount", data.withdrawamount);
    formData.append("bankName", data.bankName);
    formData.append("accountNumber", data.accountNumber);
    formData.append("upi", data.upi);
    formData.append("accountHolderName", data.accountHolderName);
    formData.append("ifsc", data.ifsc);
    formData.append("mobile", user.phone);
  
    // Convert FormData to a plain JavaScript object for logging
    const formDataObject = {};
    formData.forEach((value, key) => {
      formDataObject[key] = value;
    });
    console.log("Form Data:", formDataObject);


  
    try {
      const res = await postData("/user/withdraw", formDataObject);
      if (res.status) {
        toast.success(res.message);
      } else {
        toast.error("Something went wrong!...");
      }
    } catch (error) {
      toast.error("Failed to submit the form.");
    }
  };
  

  return (
    <div>
      <div className="deposite-container mb-4">
  <div className="container">
    <div className="text-center" style={{color: "white"}}>
      <h1>Withdrawal</h1>
    </div>
    <div className="active d-flex justify-content-center" id="via-email" style={{ marginTop: "48px" }}>
      <form className="register-form row w-75" onSubmit={handleSubmit(onSubmit)} style={{ color: "white", marginBottom: "20px" }} encType="multipart/form-data">
        {/* Phone Field */}
        <div className="col-12">
          <div className="input-group flex-nowrap mb-3 promocode align-items-center">
            <span className="input-group-text" id="addon-wrapping">
              <CiMobile3 />
            </span>
            <input
              required
              type="tel"
              className={`form-control ps-0 ${errors.phone ? "is-invalid" : ""}`}
              id="phone"
              placeholder="Your Phone"
              name="phone"
              {...register("phone", {
                required: "Phone is required",
                pattern: {
                  value: /^\d{10}$/,
                  message: "Invalid phone number",
                },
              })}
            />
            {errors.phone && (
              <div className="invalid-feedback">
                {errors.phone.message}
              </div>
            )}
          </div>
        </div>

        {/* Withdraw Amount Field */}
        <div className="col-12">
          <div className="input-group flex-nowrap mb-3 promocode align-items-center">
            <span className="input-group-text" id="addon-wrapping">
              <PiMoneyFill />
            </span>
            <input
              required
              type="number"
              className={`form-control ps-0 ${errors.withdrawamount ? "is-invalid" : ""}`}
              id="withdrawamount"
              placeholder="Withdraw Amount"
              name="withdrawamount"
              {...register("withdrawamount", {
                required: "Withdraw amount is required",
              })}
            />
            {errors.withdrawamount && (
              <div className="invalid-feedback">
                {errors.withdrawamount.message}
              </div>
            )}
          </div>
        </div>

        {/* Bank Name Field */}
        <div className="col-12">
          <div className="input-group flex-nowrap mb-3 promocode align-items-center">
            <span className="input-group-text" id="addon-wrapping">
              <RiBankLine />
            </span>
            <input
              required
              type="text"
              className={`form-control ps-0 ${errors.bankName ? "is-invalid" : ""}`}
              id="bankName"
              placeholder="Bank Name"
              name="bankName"
              {...register("bankName", {
                required: "Bank name is required",
              })}
            />
            {errors.bankName && (
              <div className="invalid-feedback">
                {errors.bankName.message}
              </div>
            )}
          </div>
        </div>

        {/* Account Number Field */}
        <div className="col-12">
          <div className="input-group flex-nowrap mb-3 promocode align-items-center">
            <span className="input-group-text" id="addon-wrapping">
              <RiBankCard2Line />
            </span>
            <input
              required
              type="text"
              className={`form-control ps-0 ${errors.accountNumber ? "is-invalid" : ""}`}
              id="accountNumber"
              placeholder="Account Number"
              name="accountNumber"
              {...register("accountNumber", {
                required: "Account number is required",
                pattern: {
                  value: /^\d{9,18}$/,
                  message: "Invalid account number",
                },
              })}
            />
            {errors.accountNumber && (
              <div className="invalid-feedback">
                {errors.accountNumber.message}
              </div>
            )}
          </div>
        </div>

        {/* UPI Field */}
        <div className="col-12">
          <div className="input-group flex-nowrap mb-3 promocode align-items-center">
            <span className="input-group-text" id="addon-wrapping">
              <RiQrCodeLine />
            </span>
            <input
              required
              type="text"
              className={`form-control ps-0 ${errors.upi ? "is-invalid" : ""}`}
              id="upi"
              placeholder="UPI ID"
              name="upi"
              {...register("upi", {
                required: "UPI ID is required",
              })}
            />
            {errors.upi && (
              <div className="invalid-feedback">
                {errors.upi.message}
              </div>
            )}
          </div>
        </div>

        {/* Account Holder Name Field */}
        <div className="col-12">
          <div className="input-group flex-nowrap mb-3 promocode align-items-center">
            <span className="input-group-text" id="addon-wrapping">
              <RiUser3Line />
            </span>
            <input
              required
              type="text"
              className={`form-control ps-0 ${errors.accountHolderName ? "is-invalid" : ""}`}
              id="accountHolderName"
              placeholder="Account Holder Name"
              name="accountHolderName"
              {...register("accountHolderName", {
                required: "Account holder name is required",
              })}
            />
            {errors.accountHolderName && (
              <div className="invalid-feedback">
                {errors.accountHolderName.message}
              </div>
            )}
          </div>
        </div>

        {/* IFSC Field */}
        <div className="col-12">
          <div className="input-group flex-nowrap mb-3 promocode align-items-center">
            <span className="input-group-text" id="addon-wrapping">
              <RiBarcodeLine />
            </span>
            <input
              required
              type="text"
              className={`form-control ps-0 ${errors.ifsc ? "is-invalid" : ""}`}
              id="ifsc"
              placeholder="IFSC Code"
              name="ifsc"
              {...register("ifsc", {
                required: "IFSC code is required",
                
              })}
            />
            {errors.ifsc && (
              <div className="invalid-feedback">
                {errors.ifsc.message}
              </div>
            )}
          </div>
        </div>

        {/* Submit Button */}
        <button type="submit" className="btn orange-btn md-btn custm-btn-2 mx-auto mt-3 mb-0 registerSubmit" id="withdraw">
          Withdraw
        </button>
      </form>
    </div>
  </div>
</div>

    </div>
  );
};

export default WithdrawalAccount;