import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useAuth } from "../ContextAndHooks/AuthContext";
import {
  fetchData,
  generateRandomEmail,
  generateTransactionId,
  postData,
} from "../api/ClientFunction";
import { useLocation } from "react-router-dom";
import WithdrawalAccount from "./WithdrawalAccount";
import useSWR from "swr";
const Withdrawal = () => {
  

  const location = useLocation();
  const [message, setMessage] = useState();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const errorParam = searchParams.get("error");
    if (errorParam) {
      setMessage(errorParam);
    }
  }, [location.search]);

  useEffect(() => {
    if (
      message ===
      "Transection Sucessfull, Amount Has been added to your account!..."
    ) {
      toast.success(message);
    } else {
      toast.error(message);
    }
  }, [message]);

  const { user, gateWayKey } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      depositAmount: "",
    },
  });
  const onSubmit = async (data) => {

    if (data.depositAmount < 1) {
      toast.error("Minimum deposit amount is 100");
      return;
    }

    const depositData = {
      key: String(gateWayKey),
      p_info: "avaitor",
      customer_mobile: user?.phone,
      customer_email: user?.email || generateRandomEmail(),
      customer_name: user?.name || user?.name_user,
      amount: data.depositAmount,
      client_txn_id: generateTransactionId(user?.phone),
      redirect_url: `${process.env.REACT_APP_API_URL}/admin/getpaymentdetails`,
    };
    const res = await postData(
      // "https://api.ekqr.in/api/create_order",
      "/user/proxy/create_order",
      depositData
    );
    if (res.data.status) {
      toast.success(res.data.msg);
      window.open(res.data.data.payment_url);
    } else {
      toast.error(res.data.msg ? res.data.msg : res.msg);
    }
  };

  return (
    <div>
     
        <div>
          <WithdrawalAccount />
        </div>
     
    </div>
  );
};

export default Withdrawal;